<template>
  <div class="admin-container">
    <template>
      <v-row>
        <v-col cols="12" md="12">
          <v-btn tile small @click="$router.push({ name: 'Projecting' })"
            >Zpět</v-btn
          >
        </v-col>
      </v-row>
      <h3 class="mt-5 mb-8 col-secodary-2">Nová složka</h3>

      <v-row>
        <v-col cols="12" md="3"
          ><v-text-field
            v-model="data.name"
            label="Název složky *"
          ></v-text-field
        ></v-col>
        <v-col cols="12" md="3"
          ><v-select
            :items="types"
            v-model="data.section"
            label="Sekce *"
            item-text="name"
            item-value="section"
          ></v-select
        ></v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="3"></v-col>
      </v-row>
      <div class="mt-10">
        <input
          style="display: none"
          ref="documentFile"
          type="file"
          class="input--file"
          @input="handleInputFile($event.target.files)"
          multiple
        />
        <div class="d-flex">
          <div v-for="(document, documentIdx) in documents" :key="documentIdx">
            <div class="document-added mr-6">
              <div class="document-text">Dokument</div>
              <div class="document-close">
                <button
                  @click="handleDeleteFile(document.documentId, documentIdx)"
                >
                  <img :src="require('@/assets/zavrit.svg')" alt="" />
                </button>
              </div>
            </div>
            <v-text-field
              style="width: 150px"
              v-model="document.name"
              label="Název dokumentu *"
            ></v-text-field>
          </div>
          <button
            class="document-input-add"
            @click="handleClick('documentFile')"
          >
            <div class="document-input-add-plus">+</div>
            <div class="document-input-add-text">Vložit dokument</div>
          </button>
        </div>
      </div>
      <v-row class="mt-8">
        <v-col class="text-right">
          <v-btn
            small
            tile
            color="secondary"
            class="mr-4"
            @click="$router.push({ name: 'Projecting' })"
            >Zrušit</v-btn
          >
          <v-btn small tile color="primary" @click="store">Uložit</v-btn>
        </v-col>
      </v-row>
    </template>
  </div>
</template>

<script>
import bus from '../plugins/bus';
import authHandler from '../api/authHandler';
import permissionDenied from '../api/permissionDenied';
import projectingService from '../api/projectingService';
import documentService from '../api/documentService';

export default {
  data() {
    return {
      data: {},
      documents: [],
      types: [
        {
          section: 'CURRENT_PROJECT_VERSION',
          name: 'Aktuální verze projektu',
        },
      ],
    };
  },
  async created() {
    permissionDenied(
      this.user,
      this.$router,
      'SUPERUSER',
      'PROJECT_MANAGER',
      'MAIN_SUPERUSER',
      'MAIN_PROJECT_MANAGER'
    );
  },
  methods: {
    async handleDeleteFile(documentId, idx) {
      try {
        bus.$emit('processing');
        await documentService._delete(documentId);
        // TODO: Splice
        this.documents.splice(idx, 1);
        bus.$emit('processing', false);
      } catch (error) {
        bus.$emit('processing', false);
        console.error(error);
        errorHandler(error);
      }
    },
    handleClick(ref) {
      this.$refs[ref].click();
    },
    async handleInputFile(f) {
      try {
        console.debug(f);
        bus.$emit('processing');
        for (let fidx = 0; fidx < f.length; fidx++) {
          const { data } = await documentService.store(f[fidx]);
          this.documents.push({ documentId: data.id, name: f[fidx].name });
        }
        bus.$emit('snackbar', {
          text: `Podařilo se nahrát dokumenty`,
          color: 'info',
        });
        bus.$emit('processing', false);
        this.$refs.documentFile.values = [];
      } catch (error) {
        bus.$emit('processing', false);
        console.error(error);
        errorHandler(error);
      }
    },
    async store() {
      try {
        bus.$emit('processing');
        const response = await projectingService.store({
          ...this.data,
          documents: this.documents,
        });
        console.debug('Response', response);
        bus.$emit('processing', false);
        bus.$emit('snackbar', {
          text: 'Uloženo',
          color: 'success',
        });
        this.$router.push({ name: 'Projecting' });
      } catch (error) {
        console.error(error);
        bus.$emit('processing', false);
        if (
          error.response &&
          error.response.data &&
          error.response.data.error.message
        ) {
          bus.$emit('snackbar', {
            text: error.response.data.error.message,
            color: 'error',
          });
        }
      }
    },
  },
  computed: {
    user() {
      return this.$store.getters.getUser;
    },
  },
  watch: {},
};
</script>

<style></style>
